import "./Project.scss";
import "./Star-Edutainment.scss";
import starEdutainmentBanner from "./../../assets/images/Star Edutainment/Banner.png";
import landingPage from "./../../assets/images/Star Edutainment/Homepage.png";
import masterClasses1 from "./../../assets/images/Star Edutainment/Masterclasses/1.png";
import masterClasses2 from "./../../assets/images/Star Edutainment/Masterclasses/2.png";
import masterClasses3 from "./../../assets/images/Star Edutainment/Masterclasses/3.png";
import liveLearning1 from "./../../assets/images/Star Edutainment/Live Learning/1.png";
import liveLearning2 from "./../../assets/images/Star Edutainment/Live Learning/2.png";
import liveLearning3 from "./../../assets/images/Star Edutainment/Live Learning/3.png";
import celebrities1 from "./../../assets/images/Star Edutainment/Celeb/1.png";
import celebrities2 from "./../../assets/images/Star Edutainment/Celeb/2.png";
import celebrities3 from "./../../assets/images/Star Edutainment/Celeb/3.png";
import celebrities4 from "./../../assets/images/Star Edutainment/Celeb/4.png";
import doubleDiamond from "../../assets/images/double-diamond.jpg";

import userPersona1 from "../../assets/images/Star Edutainment/User Persona1.png";
import userPersona2 from "../../assets/images/Star Edutainment/User Persona2.png";
import userFlow from "../../assets/images/Star Edutainment/Flow.png";

function StarEdutainment() {
  return (
    <>
      <div className="star_edutainment">
        <div className="heading">Celebrity Learning & Entertainment App</div>
        <div className="sub_heading">B2C Web App</div>
        <div className="description">
          This innovative B2C platform offers a unique blend of celebrity-driven
          learning and entertainment experiences. It features masterclasses
          taught by renowned celebrities, personalized shoutouts, and exclusive
          content, along with live sessions led by industry experts. Designed to
          bridge the gap between fans and their idols, it provides intimate
          learning opportunities and insider access to the talents and lives of
          popular celebrities. This platform represents a dynamic mix of
          personal growth, entertainment, and celebrity insights, all delivered
          through an engaging and user-friendly digital interface.
        </div>
        <div className="details_grid">
          <div className="grid_item">
            <div className="label">Role</div>
            <li>UX Strategy</li>
            <li>Product Design</li>
          </div>
          <div className="grid_item">
            <div className="label">Deliverables</div>
            <li>Web App</li>
          </div>
        </div>
        <div className="preview_image banner_bkg">
          <img
            loading="lazy"
            src={starEdutainmentBanner}
            alt="Star Edutainment Banner"
          />
        </div>

        <div className="description_block">
          <div className="heading">My UX Process</div>
          <div className="sub_heading">
            In steering the course of my projects, I draw inspiration from the
            Double Diamond Theory and the Lean UX process. This approach is my
            compass, guiding me through the critical stages of Discovery,
            Define, Develop, and Deliver. It's a journey where insight meets
            innovation, ensuring each project is not just executed but crafted
            with precision and purpose. This methodology is the backbone of my
            work, enabling me to navigate the complexities of design and deliver
            solutions that resonate deeply with our audience.
          </div>
        </div>

        <div className="img_container">
          <div className="banner_bkg">
            <img
              loading="lazy"
              src={doubleDiamond}
              alt="Double Diamond Theory"
            />
          </div>
        </div>

        <div className="description_block">
          <div className="heading">The Problem Space</div>
          <div className="desc_items">
            <ul>
              <li>
                <span className="bold">Access to Celebrities</span>:
                Traditionally, there has been a significant barrier between
                celebrities and their fans. The platform needs to facilitate
                genuine, meaningful interactions.
              </li>
              <li>
                <span className="bold">Quality of Content</span>: Ensuring the
                educational content delivered by celebrities is not only
                engaging but also genuinely informative and valuable.
              </li>
              <li>
                <span className="bold">User Engagement</span>: Keeping users
                continuously engaged in a market saturated with digital
                entertainment and educational options.
              </li>
              <li>
                <span className="bold">Personalisation</span>: Catering to a
                diverse user base with varied interests and preferences in
                celebrities, content types, and learning styles.
              </li>
              <li>
                <span className="bold">Access to Celebrities</span>:
                Traditionally, there has been a significant barrier between
                celebrities and their fans. The platform needs to facilitate
                genuine, meaningful interactions.
              </li>
              <li>
                <span className="bold">Platform Reliability</span>: Maintaining
                a stable, user-friendly interface that can handle high traffic
                and diverse content formats (videos, live sessions, etc.).
              </li>
              <li>
                <span className="bold">Monetisation Balance</span>: Finding a
                balance between being profitable and not alienating users with
                high subscription fees or excessive in-app purchases.
              </li>
            </ul>
          </div>
        </div>

        <div className="img_reel">
          <div className="reel_header">User Persona</div>
          <div className="img_container">
            <div className="banner_bkg">
              <img loading="lazy" src={userPersona1} alt="User Persona 1" />
              <img
                loading="lazy"
                className="mt-32"
                src={userPersona2}
                alt="User Persona 2"
              />
            </div>
          </div>
        </div>

        {/* <div className="description_block">
          <div className="heading">The Challenge</div>
          <div className="sub_heading">
            The primary challenge is to create a seamless, engaging platform
            that effectively bridges the gap between celebrities and fans,
            providing high-quality educational and entertainment content. This
            involves curating a diverse roster of celebrities who are not just
            popular but also capable of delivering valuable insights and
            lessons.
          </div>
          <div className="sub_heading">
            The platform must offer a user-friendly, reliable experience,
            catering to varied user interests while maintaining an effective
            monetization strategy that doesn’t detract from user experience.
            Keeping users engaged and subscribed in the long term, amidst stiff
            competition, is also a significant challenge.
          </div>
        </div> */}

        <div className="description_block">
          <div className="heading">Product Vision & Solution</div>
          <div className="sub_heading">
            My vision is to forge a pioneering platform where fans don't just
            watch their favourite celebrities from afar but interact with them
            in profound, educational, and utterly engaging ways. This isn't
            merely about fan service; it's about crafting a bridge where
            knowledge and inspiration flow freely from idols to their admirers,
            wrapped in the allure of entertainment.
          </div>
          <div className="desc_items">
            <ul>
              <li>
                <span className="bold">Deep Connections</span>: I'm setting the
                stage for fans to engage in genuine interactions with
                celebrities, turning every encounter into a memorable learning
                experience.
              </li>
              <li>
                <span className="bold">Value-Driven Content</span>: My aim is to
                pepper the celebrity dazzle with substantial learning, ensuring
                every piece of content not only entertains but enlightens.
              </li>
              <li>
                <span className="bold">Tailored Experiences</span>: I'm
                designing our platform with a keen eye on personalisation,
                adapting to the varied tastes and preferences of our audience,
                making every user's journey uniquely satisfying.
              </li>
              <li>
                <span className="bold">Smart Monetisation</span>: The strategy
                here is clear – to balance profitability with accessibility,
                ensuring our platform remains a welcoming space for all, free
                from the deterrents of excessive fees or intrusive in-app
                purchases.
              </li>
            </ul>
          </div>
          <div className="sub_heading">
            In essence, my mission is to transform the digital landscape,
            creating a sanctuary where fans and celebrities break the
            traditional barriers, engaging in a shared journey of growth,
            entertainment, and education.
          </div>
        </div>

        <div className="description_block">
          <div className="heading">Competitive Analysis</div>
          <div className="desc_items">
            <ul>
              <li>
                <span className="bold">MasterClass</span>- MasterClass is
                renowned for its exceptional production quality and courses led
                by famous celebrities and industry experts across a broad range
                of subjects. This diversity appeals to a wide audience looking
                for quality educational content. However, the platform's
                limitations lie in its lack of interactive opportunities with
                instructors, and its relatively high subscription cost may deter
                potential users seeking affordable learning options.
              </li>
              <li>
                <span className="bold">Cameo</span>- Cameo's strength lies in
                its unique offering of personalized video shoutouts from
                celebrities, making it a popular choice for gifts and special
                occasions. However, it's narrowly focused on these shoutouts and
                lacks any educational or comprehensive entertainment content.
                This limits its appeal to users seeking more than just a brief
                celebrity interaction.
              </li>
              <li>
                <span className="bold">Patreon</span>- Patreon's platform allows
                direct support for creators, offering exclusive content to
                subscribers. It's tailored to individuals who want to support
                artists and creators. The weakness of Patreon is that it's not
                focused on structured learning or interactive experiences,
                catering more to content consumption than skill development or
                personal interaction with celebrities.
              </li>
              <li>
                <span className="bold">Vimeo On Demand</span>- Vimeo On Demand
                is known for hosting high-quality video content, including some
                projects involving celebrities. It serves as a robust platform
                for content creators. However, its primary function as a content
                distribution platform rather than a learning or interactive
                platform can be seen as a limitation for those seeking
                educational content or personal celebrity interactions.
              </li>
            </ul>
          </div>
        </div>

        <div className="img_reel">
          <div className="heading">User Journey Map</div>
          <div className="img_container">
            <div className="banner_bkg">
              <img
                loading="lazy"
                src={userFlow}
                alt="Star Edutainment User Flow"
              />
            </div>
          </div>
        </div>

        <div className="heading padding-top">Final Design</div>

        <div className="img_reel">
          <div className="reel_header">Landing</div>
          <div className="img_container">
            <div className="banner_bkg">
              <img loading="lazy" src={landingPage} alt="Landing Page" />
            </div>
            <div className="img_caption">Landing Page</div>
          </div>
        </div>

        <div className="img_reel">
          <div className="reel_header">Masterclasses</div>
          <div className="img_container">
            <div className="banner_bkg">
              <img
                loading="lazy"
                src={masterClasses1}
                alt="Masterclasses Landing"
              />
            </div>
            <div className="img_caption">Masterclasses Landing</div>
          </div>

          <div className="img_container">
            <div className="banner_bkg">
              <img
                loading="lazy"
                src={masterClasses2}
                alt="Masterclasses Detail Pre Purchase"
              />
            </div>
            <div className="img_caption">Masterclasses Detail Pre Purchase</div>
          </div>

          <div className="img_container">
            <div className="banner_bkg">
              <img
                loading="lazy"
                src={masterClasses3}
                alt="Masterclasses Detail Post Purchase"
              />
            </div>
            <div className="img_caption">
              Masterclasses Detail Post Purchase
            </div>
          </div>
        </div>

        <div className="img_reel">
          <div className="reel_header">Live Learning</div>
          <div className="img_container">
            <div className="banner_bkg">
              <img
                loading="lazy"
                src={liveLearning1}
                alt="Live Learning Landing"
              />
            </div>
            <div className="img_caption">Live Learning Landing</div>
          </div>

          <div className="img_container">
            <div className="banner_bkg">
              <img
                loading="lazy"
                src={liveLearning2}
                alt="Live Learning Detail Pre Purchase"
              />
            </div>
            <div className="img_caption">Live Learning Detail Pre Purchase</div>
          </div>

          <div className="img_container">
            <div className="banner_bkg">
              <img
                loading="lazy"
                src={liveLearning3}
                alt="Live Learning Detail Post Purchase"
              />
            </div>
            <div className="img_caption">
              Live Learning Detail Post Purchase
            </div>
          </div>
        </div>

        <div className="img_reel">
          <div className="reel_header">Celebrities</div>
          <div className="img_container">
            <div className="banner_bkg">
              <img
                loading="lazy"
                src={celebrities1}
                alt="Celebrities Landing"
              />
            </div>
            <div className="img_caption">Celebrities Landing</div>
          </div>

          <div className="img_container">
            <div className="banner_bkg">
              <img
                loading="lazy"
                src={celebrities2}
                alt="Celebrities Profile"
              />
            </div>
            <div className="img_caption">Celebrities Profile</div>
          </div>

          <div className="grid">
            <div className="img_container">
              <div className="banner_bkg">
                <img loading="lazy" src={celebrities4} alt="Payment" />
              </div>

              <div className="img_caption">Celebrities Profile (Payment)</div>
            </div>

            <div className="img_container">
              <div className="banner_bkg">
                <img loading="lazy" src={celebrities3} alt="Video Shoutout" />
              </div>

              <div className="img_caption">
                Celebrities Profile (Video Shoutout)
              </div>
            </div>
          </div>
        </div>

        <div className="description_block">
          <div className="heading">Result & Takeaways</div>
          <div className="sub_heading">
            Launching our celebrity engagement platform was akin to navigating
            the exhilarating yet challenging waters of an early-stage startup.
            This journey was not just about creating connections; it was a
            profound learning experience that honed our focus on lean operations
            and strategic prioritisation.
          </div>
          <div className="desc_items">
            <ul>
              <li>
                The venture into this digital platform underscored the essence
                of agility and precision in startup culture. We learned to
                channel our resources into developing a Minimum Viable Product
                (MVP), highlighting the criticality of delivering value
                efficiently. This focus enabled us to swiftly adapt to user
                feedback, enhancing the platform's relevance and impact.
              </li>
              <li>
                A pivotal shift in our approach was moving away from an
                overemphasis on UI details to prioritising user experience
                flows. This reorientation was crucial in refining the platform's
                usability, ensuring that our design efforts were not just about
                aesthetics but about facilitating seamless user journeys.
              </li>
              <li>
                Above all, the project reinforced the importance of staying
                grounded in the problem we aimed to solve. Amidst the myriad
                tasks and challenges, maintaining a laser focus on addressing
                our users' needs—connecting them meaningfully with
                celebrities—remained our guiding star. This commitment to
                solving user pain points ensured that every feature and update
                was purpose-driven, enhancing the platform's value proposition.
              </li>
            </ul>
          </div>
          <div className="sub_heading">
            Embarking on this project was a testament to the power of strategic
            focus, user-centred design, and the continuous iteration of
            solutions that genuinely address user needs, laying a solid
            foundation for future enhancements and growth.
          </div>
        </div>
      </div>
    </>
  );
}
export default StarEdutainment;
