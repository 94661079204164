import "./Project.scss";
import "./Hawk-Trade.scss";
import htBanner from "../../assets/images/Hawk Trade/Image.jpg";
import userFlow from "../../assets/images/Hawk Trade/User Flow.png";
import signUp from "../../assets/images/Hawk Trade/screen1.png";
import verify from "../../assets/images/Hawk Trade/screen2.png";
import editEmail from "../../assets/images/Hawk Trade/screen3.png";
import accountCreation from "../../assets/images/Hawk Trade/screen4.png";
import profileCreation from "../../assets/images/Hawk Trade/screen5.png";
import personalDetails from "../../assets/images/Hawk Trade/screen6.png";
import orgDetails from "../../assets/images/Hawk Trade/screen7.png";
import kycDocs from "../../assets/images/Hawk Trade/screen8.png";
import bankDetails from "../../assets/images/Hawk Trade/screen9.png";
import profileComplete from "../../assets/images/Hawk Trade/screen10.png";
import newBooking from "../../assets/images/Hawk Trade/screen11.png";
import blData from "../../assets/images/Hawk Trade/screen12.png";
import noblData from "../../assets/images/Hawk Trade/screen13.png";
import csfEstimationsList from "../../assets/images/Hawk Trade/screen14.png";
import editUserPrefs from "../../assets/images/Hawk Trade/screen15.png";
import priceBreakup from "../../assets/images/Hawk Trade/screen16.png";
import csfDetails from "../../assets/images/Hawk Trade/screen17.png";
import bookingSummary from "../../assets/images/Hawk Trade/screen18.png";
import docuSection from "../../assets/images/Hawk Trade/screen19.png";
import docuUploader from "../../assets/images/Hawk Trade/screen20.png";
import docuUploadSuccess from "../../assets/images/Hawk Trade/screen21.png";
import bookingSuccess from "../../assets/images/Hawk Trade/screen22.png";
import wireframing1 from "../../assets/images/Hawk Trade/Booking.png";
import wireframing2 from "../../assets/images/Hawk Trade/Booking – 1.png";
import wireframing3 from "../../assets/images/Hawk Trade/Booking – 2.png";
import doubleDiamond from "../../assets/images/double-diamond.jpg";

function HawkTrade() {
  return (
    <>
      <div className="hawkTrade">
        <div className="heading">Hawk Trade</div>
        <div className="sub_heading">B2B Web App</div>
        <div className="description">
          Hawk Trade is a one stop customer based digital platform which provide
          various service options for the container movement. This platform also
          enable to any exporter or importer can come on-board, connect with the
          service partner directly and book & manage the containers.
        </div>
        <div className="details_grid">
          <div className="grid_item">
            <div className="label">Organization</div>
            <li>CNB Logitech</li>
          </div>
          <div className="grid_item">
            <div className="label">Role</div>
            <li>Product Owner</li>
            <li>UX Strategy</li>
            <li>Product Design</li>
          </div>
          <div className="grid_item">
            <div className="label">Deliverables</div>
            <li>Web App</li>
          </div>
        </div>
        <div className="preview_image banner_bkg">
          <img loading="lazy" src={htBanner} alt="Hawk Trade Banner" />
          {/* <img loading="lazy" src={htBanner} alt="Hawk Trade Banner" /> */}
        </div>

        <div className="description_block">
          <div className="heading">My UX Process</div>
          <div className="sub_heading">
            In steering the course of my projects, I draw inspiration from the
            Double Diamond Theory and the Lean UX process. This approach is my
            compass, guiding me through the critical stages of Discovery,
            Define, Develop, and Deliver. It's a journey where insight meets
            innovation, ensuring each project is not just executed but crafted
            with precision and purpose. This methodology is the backbone of my
            work, enabling me to navigate the complexities of design and deliver
            solutions that resonate deeply with our audience.
          </div>
        </div>

        <div className="img_container">
          <div className="banner_bkg">
            <img
              loading="lazy"
              src={doubleDiamond}
              alt="Double Diamond Theory"
            />
          </div>
        </div>

        <div className="description_block">
          <div className="heading">The Problem Space</div>
          <div className="sub_heading">
            In my journey to revolutionise container logistics with Hawk Trade,
            I navigated through a maze of outdated practices to unveil critical
            pain points:
          </div>
          <div className="desc_items">
            <ul>
              <li>
                <span className="bold">Fixed Pricing Rigidity</span>: Locked
                into prices with no negotiation leverage.
              </li>
              <li>
                <span className="bold">Invisible Competitive Offers</span>:
                Blind to the array of choices hidden in the shadows.
              </li>
              <li>
                <span className="bold">Immutable Orders</span>: Decisions once
                made were set in stone, unchangeable.
              </li>
              <li>
                <span className="bold">Opaque Pricing</span>: Venturing through
                the fog without a breakdown of costs.
              </li>
              <li>
                <span className="bold">Process Uncertainty</span>: A journey
                within the yard, veiled in mystery.
              </li>
              <li>
                <span className="bold">Inflexible Gate Passes</span>: Bound by
                the unyielding schedule of gate pass issuance.
              </li>
              <li>
                <span className="bold">Absence of Digital Transactions</span>:
                Anchored in the era of paper trails, distant from digital
                convenience.
              </li>
            </ul>
          </div>
        </div>

        <div className="description_block">
          <div className="heading">Product Vision & Solution</div>
          <div className="sub_heading">
            In my quest to redefine the EXIM container booking experience, I set
            out with a clear objective: to meticulously craft a web app that
            simplifies the complexities of booking and tracking full load import
            containers. This digital tool is designed not just to facilitate
            transactions but to ensure a smooth, hassle-free journey from start
            to finish.
          </div>
          <div className="desc_items">
            <ul>
              <li>
                <span className="bold">Entering the Container's Tale</span>: Our
                journey begins with the user sharing their container's story—its
                identity, dimensions, weight, and type. This initial step is
                pivotal, laying the groundwork for what's to come.
              </li>
              <li>
                <span className="bold">Selecting the Ideal CFS/ICD</span>: With
                the container's details in hand, our system springs into action,
                filtering through options to present a curated list of CFS/ICDs.
                Users can then choose their preferred partner based on critical
                factors such as price, ratings, and payment options—empowering
                them to make informed decisions tailored to their needs.
              </li>
              <li>
                <span className="bold">Review, Confirm, and Launch</span>: In
                the journey's final stretch, users are given the opportunity to
                review and tweak their details, ensuring everything aligns
                perfectly with their expectations. This step culminates in the
                uploading of necessary documents, setting the stage for the
                creation of a new booking.
              </li>
            </ul>
          </div>
        </div>

        <div className="description_block">
          <div className="heading">Competitive Analysis</div>
          <div className="sub_heading">
            Since the space of container bookings are saturated, I wanted to
            explore what was currently successful and improved. Well, The
            competition is very less what the solution I am trying to come up
            with. I found top 3 service provider on this space.
          </div>
          <div className="desc_items">
            <ul>
              <li>
                <span className="bold">Cogoport</span> - Although they are into
                this space but they are not giving the solution after arriving a
                container to the port. These guys are more into Location Based
                solution.
              </li>
              <li>
                <span className="bold">Searates by DP World</span> - Searates
                solutions are amazing but only problem. Searates solves only
                port to port delivery.
              </li>
              <li>
                <span className="bold">Maersk</span> - The company has their own
                solution but also they tied with few container yards. User won’t
                get much option and flexibility.
              </li>
            </ul>
          </div>
        </div>

        <div className="preview_image user_flow">
          <div className="heading">User Journey Map</div>
          <img loading="lazy" src={userFlow} alt="Hawk Trade User Flow" />
        </div>

        <div className="description_block">
          <div className="heading">Wireframing the solution</div>
          <div className="sub_heading">
            In response to the challenges identified, I embarked on a journey to
            sketch the blueprint of our solution. My first step was to craft
            wireframes that served as a visual conversation starter among our
            Product, Engineering teams, and the users. These initial sketches
            were instrumental in refining the structure and flow of our wizard
            form. By focusing on a standardized visual hierarchy, I aimed to lay
            the groundwork for a wizard component that would intuitively guide
            users through the process, ensuring every interaction was deliberate
            and every layout choice made with precision. This stage was not just
            about drawing lines and boxes; it was about laying the foundation
            for a solution that would seamlessly address our users' pain points
            with elegance and functionality.
          </div>
        </div>

        <div className="img_reel">
          <div className="img_container mb-32">
            <div className="banner_bkg">
              <img loading="lazy" src={wireframing1} alt="Wireframe" />
            </div>
          </div>

          <div className="img_container mb-32">
            <div className="banner_bkg">
              <img loading="lazy" src={wireframing2} alt="Wireframe" />
            </div>
          </div>

          <div className="img_container mb-32">
            <div className="banner_bkg">
              <img loading="lazy" src={wireframing3} alt="Wireframe" />
            </div>
          </div>
        </div>

        <div className="heading padding-top">Final Design</div>

        <div className="img_reel">
          <div className="reel_header">Onboarding</div>
          <div className="img_container">
            <div className="banner_bkg">
              <img loading="lazy" src={signUp} alt="signUp" />
            </div>
            <div className="img_caption">Sign up</div>
          </div>
          <div className="grid">
            <div className="img_container">
              <div className="banner_bkg">
                <img loading="lazy" src={verify} alt="verify" />
              </div>

              <div className="img_caption">Verify Email & Phone</div>
            </div>
            <div className="img_container">
              <div className="banner_bkg">
                <img loading="lazy" src={editEmail} alt="editEmail" />
              </div>

              <div className="img_caption">Edit Email</div>
            </div>
            <div className="img_container">
              <div className="banner_bkg">
                <img
                  loading="lazy"
                  src={accountCreation}
                  alt="accountCreation"
                />
              </div>
              <div className="img_caption">Successful Account Creation</div>
            </div>
            <div className="img_container">
              <div className="banner_bkg">
                <img
                  loading="lazy"
                  src={profileCreation}
                  alt="profileCreation"
                />
              </div>
              <div className="img_caption">Profile Creation Message</div>
            </div>
          </div>
          <div className="reel_header">Create Profile</div>
          <div className="grid">
            <div className="img_container">
              <div className="banner_bkg">
                <img
                  loading="lazy"
                  src={personalDetails}
                  alt="personalDetails"
                />
              </div>
              <div className="img_caption">Personal Details</div>
            </div>
            <div className="img_container">
              <div className="banner_bkg">
                <img loading="lazy" src={orgDetails} alt="orgDetails" />
              </div>
              <div className="img_caption">Organisation Details</div>
            </div>
            <div className="img_container">
              <div className="banner_bkg">
                <img loading="lazy" src={kycDocs} alt="kycDocs" />
              </div>
              <div className="img_caption">KYC Documents</div>
            </div>
            <div className="img_container">
              <div className="banner_bkg">
                <img loading="lazy" src={bankDetails} alt="bankDetails" />
              </div>
              <div className="img_caption">Bank Details</div>
            </div>
          </div>
          <div className="img_container">
            <div className="banner_bkg">
              <img loading="lazy" src={profileComplete} alt="profileComplete" />
            </div>
            <div className="img_caption">Profile Complete</div>
          </div>
          <div className="reel_header">Create New Booking</div>
          <div className="img_container">
            <div className="banner_bkg">
              <img loading="lazy" src={newBooking} alt="newBooking" />
            </div>
            <div className="img_caption">Create New Booking</div>
          </div>
          <div className="grid">
            <div className="img_container">
              <div className="banner_bkg">
                <img loading="lazy" src={blData} alt="blData" />
              </div>
              <div className="img_caption">Data Fetched From BL Number</div>
            </div>
            <div className="img_container">
              <div className="banner_bkg">
                <img loading="lazy" src={noblData} alt="noblData" />
              </div>
              <div className="img_caption">If There is No Data</div>
            </div>
          </div>
          <div className="img_container">
            <div className="banner_bkg">
              <img
                loading="lazy"
                src={csfEstimationsList}
                alt="csfEstimationsList"
              />
            </div>
            <div className="img_caption">
              CFS Details Based On User Provided Data
            </div>
          </div>
          <div className="grid">
            <div className="img_container">
              <div className="banner_bkg">
                <img loading="lazy" src={editUserPrefs} alt="editUserPrefs" />
              </div>
              <div className="img_caption">
                Change / Edit The User Preferences
              </div>
            </div>
            <div className="img_container">
              <div className="banner_bkg">
                <img loading="lazy" src={priceBreakup} alt="priceBreakup" />
              </div>
              <div className="img_caption">Price Breakup</div>
            </div>
          </div>

          <div className="img_container">
            <div className="banner_bkg">
              <img loading="lazy" src={csfDetails} alt="csfDetails" />
            </div>
            <div className="img_caption">Selected CFS Info</div>
          </div>
          <div className="img_container">
            <div className="banner_bkg">
              <img loading="lazy" src={bookingSummary} alt="bookingSummary" />
            </div>
            <div className="img_caption">Booking Summary</div>
          </div>
          <div className="img_container">
            <div className="banner_bkg">
              <img loading="lazy" src={docuSection} alt="docuSection" />
            </div>
            <div className="img_caption">Document Section</div>
          </div>
          <div className="grid">
            <div className="img_container">
              <div className="banner_bkg">
                <img loading="lazy" src={docuUploader} alt="docuUploader" />
              </div>
              <div className="img_caption">Document Uploader</div>
            </div>
            <div className="img_container">
              <div className="banner_bkg">
                <img
                  loading="lazy"
                  src={docuUploadSuccess}
                  alt="docuUploadSuccess"
                />
              </div>
              <div className="img_caption">Document Upload Success</div>
            </div>
          </div>
          <div className="img_container">
            <div className="banner_bkg">
              <img loading="lazy" src={bookingSuccess} alt="bookingSuccess" />
            </div>
            <div className="img_caption">A Booking Placed Successfully</div>
          </div>

          <div className="description_block">
            <div className="heading">Result & Takeaways</div>
            <div className="sub_heading">
              Since launching the revamped Hawk Trade platform, the
              transformation has been nothing short of remarkable. The influx of
              service desk complaints has plummeted, reflecting the platform's
              enhanced usability and effectiveness. User accolades have poured
              in, celebrating the streamlined container booking process that now
              significantly conserves their time.
            </div>

            <div className="sub_heading">
              Reflecting on the journey with Hawk Trade, several critical
              takeaways have emerged:
            </div>
            <div className="desc_items">
              <ul>
                <li>
                  <span className="bold"> Launching with an MVP</span>:
                  Embarking with a Minimum Viable Product was a strategic
                  maneuver. It not only shielded the project from the allure of
                  expanding scope but also ensured we delivered a polished,
                  functional product within our timeline. This approach allowed
                  us to focus on core features, making sure they were executed
                  flawlessly before considering additional enhancements.
                </li>
                <li>
                  <span className="bold">Continuous User Testing</span>: The
                  realisation dawned that user testing is not a milestone to be
                  checked off but a continuous journey. The design process is
                  cyclical, revolving around perpetual improvements based on
                  user feedback. Engaging with our users' experiences and
                  insights has been pivotal in refining and evolving the
                  platform to better meet their needs.
                </li>
                <li>
                  <span className="bold">Early Engineering Involvement</span>:
                  Integrating our engineering team from the outset proved
                  invaluable. This collaboration fostered a shared understanding
                  of what was possible, both technically and creatively, paving
                  the way for a design strategy informed by real-world
                  limitations and opportunities. This foresight significantly
                  mitigated the need for redesigns or reworks, streamlining the
                  development process.
                </li>
              </ul>
            </div>
            <div className="sub_heading">
              Hawk Trade's journey underscores the power of strategic planning,
              user-centric design, and cross-functional collaboration in
              creating solutions that not only meet but exceed user
              expectations.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default HawkTrade;
