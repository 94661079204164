import { Button } from "@mui/material";
import Contact from "../Contact/Contact";
import "./Blog.scss";

function Blog() {
  const openBlog = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      <div className="blog_container">
        <div className="heading">Blogs</div>
        <div className="sub_heading">
          Stay up to date for crafting delightful experiences
        </div>
        <div className="blogs_grid">
          <div className="blog-card blog_bg_5">
            <div className="blog-screen">
              <img
                src={require("./../../assets/images/blogs/3d_render_illustration_of_robot_head_core_brain_isolated_premium_psd-removebg-preview.png")}
                alt="Robot Head Icon"
              />
            </div>
            <div className="blog-details">
              <div className="blog-date">Feb 1, 2024</div>
              <div className="blog-name">
                AI’s Transformative Impact on UX Design
              </div>
            </div>
            <Button
              onClick={() => {
                openBlog(
                  "https://medium.com/@imsam/ais-transformative-impact-on-ux-design-ab7f5766c0a5"
                );
              }}
            >
              Read More
            </Button>
          </div>

          <div className="blog-card blog_bg_4">
            <div className="blog-screen">
              <img
                src={require("./../../assets/images/blogs/ChatGPT-3D-Icon.png")}
                alt="ChatGPT 3D Icon"
              />
            </div>
            <div className="blog-details">
              <div className="blog-date">Jan 19, 2024</div>
              <div className="blog-name">
                Transform Your Brand with LogoGPT: The Future of AI-Driven Logo
                Design
              </div>
            </div>
            <Button
              onClick={() => {
                openBlog(
                  "https://medium.com/@imsam/transform-your-brand-with-logogpt-the-future-of-ai-driven-logo-design-4632aaa121d0"
                );
              }}
            >
              Read More
            </Button>
          </div>

          <div className="blog-card blog_bg_3">
            <div className="blog-screen">
              <img
                src={require("./../../assets/images/blogs/future-bot-app.png")}
                alt="AI Bot"
              />
            </div>
            <div className="blog-details">
              <div className="blog-date">Aug 3, 2023</div>
              <div className="blog-name">
                7 Revolutionary AI Tools Transforming UX/UI Design: Boosting
                Productivity and Creativity!
              </div>
            </div>
            <Button
              onClick={() => {
                openBlog(
                  "https://medium.com/@imsam/7-revolutionary-ai-tools-transforming-ux-ui-design-boosting-productivity-and-creativity-7ed1ebb6ebad"
                );
              }}
            >
              Read More
            </Button>
          </div>

          <div className="blog-card blog_bg_2">
            <div className="blog-screen">
              <img
                src={require("./../../assets/images/blogs/figma.png")}
                alt="Dark and
                Light Modes"
              />
            </div>
            <div className="blog-details">
              <div className="blog-date">Jul 19, 2023</div>
              <div className="blog-name">
                Figma Color Variable: Creating Dynamic Designs with Dark and
                Light Modes
              </div>
            </div>
            <Button
              onClick={() => {
                openBlog(
                  "https://medium.com/@imsam/figma-color-variable-creating-dynamic-designs-with-dark-and-light-modes-b451db9dc5ed"
                );
              }}
            >
              Read More
            </Button>
          </div>

          <div className="blog-card blog_bg_1">
            <div className="blog-screen">
              <img
                src={require("./../../assets/images/blogs/zeplin.png")}
                alt="Design Handoff Process"
              />
            </div>
            <div className="blog-details">
              <div className="blog-date">Jan 18, 2023</div>
              <div className="blog-name">
                Streamlining the Design Handoff Process: Tips and Tricks for
                Effective Collaboration
              </div>
            </div>
            <Button
              onClick={() => {
                openBlog(
                  "https://medium.com/@imsam/streamlining-the-design-handoff-process-tips-and-tricks-for-effective-collaboration-87452379f8cc"
                );
              }}
            >
              Read More
            </Button>
          </div>
        </div>
      </div>
      <Contact fromProjects="true"></Contact>
    </>
  );
}

export default Blog;
