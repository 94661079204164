import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import { FaLongArrowAltUp } from 'react-icons/fa';

export default function ScrollToTopBtn() {
  const [showButton, setShowButton] = useState(false);
  const scrollToTop = () => {
    window.scrollTo(0, 0);
    console.log('scrollToTop');
  };

  useEffect(() => {
    const handleScrollButtonVisibility = () => {
      window.pageYOffset > 1000 ? setShowButton(true) : setShowButton(false);
    };
    window.addEventListener('scroll', handleScrollButtonVisibility);
    return () => {
      window.removeEventListener('scroll', handleScrollButtonVisibility);
    };
  }, []);
  return (
    <>
      {showButton && (
        <Box
          className="scroll_to_top_btn"
          sx={{ '& > :not(style)': { m: 1 } }}
          style={{
            position: 'fixed',
            bottom: '2.5rem',
            right: '2rem',
            zIndex: 1,
          }}
        >
          <Fab
            style={{
              background: '#21262b',
            }}
            onClick={scrollToTop}
          >
            <FaLongArrowAltUp
              style={{
                fill: '#ffffff',
                fontSize: '1.5rem',
                transition: 'all 0.5s ease',
                transform: `rotate : 0.5turn})`,
              }}
            ></FaLongArrowAltUp>
          </Fab>
        </Box>
      )}{' '}
    </>
  );
}
