import './Project.scss';
import './iHawk.scss';
import ihawkBanner from '../../assets/images/iHawk Custom/ihawk_customs.png';
import signin from '../../assets/images/iHawk Custom/signin.png';
import signinUsingOtp from '../../assets/images/iHawk Custom/signin_otp.png';
import signinUsingEmail from '../../assets/images/iHawk Custom/signinUsingEmail.png';
import signinUsingEmailPwd from '../../assets/images/iHawk Custom/signinUsingEmailPwd.png';
import createAc from '../../assets/images/iHawk Custom/createAc.png';
import verify from '../../assets/images/iHawk Custom/verify.png';
import creation_success from '../../assets/images/iHawk Custom/creation_success.png';

import search1 from '../../assets/images/iHawk Custom/search1.png';
import search2 from '../../assets/images/iHawk Custom/search2.png';
import search3 from '../../assets/images/iHawk Custom/search3.png';
import search4 from '../../assets/images/iHawk Custom/search4.png';
import search5 from '../../assets/images/iHawk Custom/search5.png';
import search6 from '../../assets/images/iHawk Custom/search6.png';
import search7 from '../../assets/images/iHawk Custom/search7.png';
import search8 from '../../assets/images/iHawk Custom/search8.png';

import igm_search1 from '../../assets/images/iHawk Custom/igm_search1.png';
import igm_search2 from '../../assets/images/iHawk Custom/igm_search2.png';
import igm_search3 from '../../assets/images/iHawk Custom/igm_search3.png';
import igm_search4 from '../../assets/images/iHawk Custom/igm_search4.png';
import igm_search5 from '../../assets/images/iHawk Custom/igm_search5.png';

import activity1 from '../../assets/images/iHawk Custom/activity1.png';
import activity2 from '../../assets/images/iHawk Custom/activity2.png';
import activity3 from '../../assets/images/iHawk Custom/activity3.png';
import activity4 from '../../assets/images/iHawk Custom/activity4.png';
import activity5 from '../../assets/images/iHawk Custom/activity5.png';

function IHawk() {
  return (
    <>
      <div className="heading">iHawk Custom</div>
      <div className="sub_heading">B2C Mobile App</div>
      <div className="description">
        Hawk CO Mobile Application allows users to get real time location and
        other process updates of their in-transit cargo. The product helps end
        users such as CHAs, importers/exporters and customs to check the status
        of their cargo in realtime.
      </div>
      <div className="details_grid">
        <div className="grid_item">
          <div className="label">Organization</div>
          <li>CNB Logitech</li>
        </div>
        <div className="grid_item">
          <div className="label">Role</div>
          <li>Product Owner</li>
          <li>UX Strategy</li>
          <li>Product Design</li>
        </div>
        <div className="grid_item">
          <div className="label">Deliverables</div>
          <li>iOS & Android Mobile App</li>
        </div>
      </div>
      <div className="preview_image ">
        <img loading="lazy" src={ihawkBanner} alt="Hawk Trade Banner" />
      </div>
      <div className="description_block">
        <div className="heading">Defining the Pain Points</div>
        <div className="sub_heading">
          Tracking container location can be a challenging task, as there are
          few concrete solutions available in the market. While it is easy to
          obtain updates about containers, there are no solutions that allow
          customs officers to obtain real-time container updates while
          completing necessary actions. Customs officers often face a lengthy
          process and extensive paperwork in order to clear containers.
        </div>
      </div>
      <div className="description_block">
        <div className="heading">The Challenge</div>
        <div className="sub_heading">
          The most effective method of finding what you seek is to analyse the
          data, and the most efficient way to do so is with a modern tool that
          provides access to all of your container data in real time.
        </div>
        <div className="sub_heading">
          The objective is to create a platform that allows users to search for,
          track the location of, and hold or clear containers with just a single
          click.
        </div>
      </div>

      <div className="description_block">
        <div className="heading">Sign In Options</div>
        <div className="sub_heading">
          The sign-in option requires the user to enter their registered phone
          number and receive a One-Time Password (OTP) via text message. The
          user then enters this OTP into the sign-in form to access the product.
        </div>
        <div className="sub_heading">
          The advantage of this option is that it provides an extra layer of
          security, as the user must have access to their registered phone
          number in order to sign in. This makes it more difficult for
          unauthorized users to gain access to the user's account. Additionally,
          it can be a quicker sign-in process as the user does not need to
          remember their password.
        </div>
      </div>

      <div className="img_reel">
        <div className="grid col_3_grid">
          <div className="img_container">
            <img loading="lazy" src={signin} alt="signin" />
          </div>
          <div className="img_container">
            <img loading="lazy" src={signinUsingOtp} alt="signinUsingOtp" />
          </div>
        </div>
      </div>

      <div className="description_block">
        <div className="sub_heading">
          Another option requires the user to enter their registered email
          address and password to access the product. The user must have
          previously created an account with the product and chosen a unique
          password to use for sign-in.
        </div>
        <div className="sub_heading">
          The advantage of this option is that it is a familiar sign-in method
          that most users are comfortable with. It also allows for a more
          complex and secure password, as the user can choose a unique password
          and update it as needed.
        </div>
      </div>

      <div className="img_reel">
        <div className="grid col_3_grid">
          <div className="img_container">
            <img loading="lazy" src={signinUsingEmail} alt="signinUsingEmail" />
          </div>
          <div className="img_container">
            <img
              loading="lazy"
              src={signinUsingEmailPwd}
              alt="signinUsingEmailPwd"
            />
          </div>
        </div>
      </div>

      <div className="description_block">
        <div className="heading">Create Account</div>
        <div className="sub_heading">
          On the other hand to access the product user need to create an
          account. And the process as follows:
        </div>
        <div className="sub_heading">
          Providing Details: The first step in creating an account is for the
          user to provide their personal information such as their name, phone
          number, email, and create a password. This information will be used to
          identify the user when they sign in to the iHawk in the future.
        </div>
        <div className="sub_heading">
          Verification: After the user has provided their details, they will
          need to go through a verification process. This typically involves
          sending two different OTPs, one to the user's phone number and one to
          their email address. The purpose of this is to verify that the user
          has access to both their phone number and email, which provides an
          extra layer of security and helps prevent fraudulent accounts from
          being created.
        </div>
        <div className="sub_heading">
          The advantage of this option is that it provides an extra layer of
          security, as the user must have access to their registered phone
          number in order to sign in. This makes it more difficult for
          unauthorized users to gain access to the user's account. Additionally,
          it can be a quicker sign-in process as the user does not need to
          remember their password.
        </div>
        <div className="sub_heading">
          The user will be prompted to enter the OTPs they receive, which will
          confirm that they have access to the phone number and email address
          they provided. This process can vary depending on the product, but
          typically the OTPs are sent within a few minutes and are only valid
          for a limited amount of time.
        </div>
        <div className="sub_heading">
          Account Creation: Once the user has successfully verified their phone
          number and email address, their account will be created and they will
          be able to log in to the iHawk using their email and password. From
          this point on, the user can access the iHawk features and services.
        </div>

        <div className="sub_heading">
          It's worth noting that the verification process is an important step
          in creating an account as it helps ensure that only authorised users
          are able to access the product. Additionally, it's important for users
          to choose a strong and unique password to help protect their account
          from unauthorised access.
        </div>
      </div>

      <div className="img_reel">
        <div className="grid col_3_grid">
          <div className="img_container">
            <img loading="lazy" src={createAc} alt="createAc" />
          </div>
          <div className="img_container">
            <img loading="lazy" src={verify} alt="verify" />
          </div>
          <div className="img_container">
            <img loading="lazy" src={creation_success} alt="creation_success" />
          </div>
        </div>
      </div>

      <div className="description_block">
        <div className="heading">Container Search</div>
        <div className="sub_heading">
          The search container feature in iHawk is both highly important and
          frequently used. By entering the container number, users can easily
          search for any container, and the backend engine will provide all the
          necessary information that matches the entered number, including the
          container's process, date, time, and location. In addition, users have
          the flexibility to take action against any container by selecting from
          three options: Hold, Ask document, and Examination.
        </div>
        <div className="sub_heading">
          For example, a custom officer can put a container on hold until
          inspection by clicking the "Put On Hold" button and providing the
          reason for holding the container, along with photo evidence.
          Similarly, in the Examination area, an officer can request more
          inspection against any container. However, the custom officer has the
          authority to ask for any related document before releasing the
          container. All of these processes are easy to carry out within the
          iHawk system.
        </div>
      </div>

      <div className="img_reel">
        <div className="grid col_3_grid">
          <div className="img_container">
            <img loading="lazy" src={search1} alt="search1" />
          </div>
          <div className="img_container">
            <img loading="lazy" src={search2} alt="search2" />
          </div>
          <div className="img_container">
            <img loading="lazy" src={search3} alt="search3" />
          </div>
          <div className="img_container">
            <img loading="lazy" src={search4} alt="search4" />
          </div>
          <div className="img_container">
            <img loading="lazy" src={search5} alt="search5" />
          </div>
          <div className="img_container">
            <img loading="lazy" src={search6} alt="search6" />
          </div>
          <div className="img_container">
            <img loading="lazy" src={search7} alt="search7" />
          </div>
          <div className="img_container">
            <img loading="lazy" src={search8} alt="search8" />
          </div>
        </div>
      </div>

      <div className="description_block">
        <div className="heading">IGM Search</div>
        <div className="sub_heading">
          The IGM Search feature holds the same level of importance as the
          Container Search feature. The main difference between the two is that
          an IGM is associated with multiple container numbers, which allows
          users to perform actions on multiple containers at once.
        </div>
        <div className="sub_heading">
          For instance, a custom officer can put a complete IGM on hold, and
          iHawk system will put on hold all the containers linked with that IGM.
          Similarly, the Examination and Documents features can also be applied
          to all containers associated with a particular IGM.
        </div>
      </div>

      <div className="img_reel">
        <div className="grid col_3_grid">
          <div className="img_container">
            <img loading="lazy" src={igm_search1} alt="igm_search1" />
          </div>
          <div className="img_container">
            <img loading="lazy" src={igm_search2} alt="igm_search2" />
          </div>
          <div className="img_container">
            <img loading="lazy" src={igm_search3} alt="igm_search3" />
          </div>
          <div className="img_container">
            <img loading="lazy" src={igm_search4} alt="igm_search4" />
          </div>
          <div className="img_container">
            <img loading="lazy" src={igm_search5} alt="igm_search5" />
          </div>
        </div>
      </div>

      <div className="description_block">
        <div className="heading">Activity</div>
        <div className="sub_heading">
          This section of the app allows users to track and manage all of their
          activities, including actions taken by the officer on any container or
          IGM, as well as communication between customs and CHA. Users can also
          view and manage requested or received documents. Essentially, this
          section serves as a historical reference for customs, which can be
          useful for future reference.
        </div>
      </div>

      <div className="img_reel">
        <div className="grid col_3_grid">
          <div className="img_container">
            <img loading="lazy" src={activity1} alt="activity1" />
          </div>
          <div className="img_container">
            <img loading="lazy" src={activity2} alt="activity2" />
          </div>
          <div className="img_container">
            <img loading="lazy" src={activity3} alt="activity3" />
          </div>
          <div className="img_container">
            <img loading="lazy" src={activity4} alt="activity4" />
          </div>
          <div className="img_container">
            <img loading="lazy" src={activity5} alt="activity5" />
          </div>
        </div>
      </div>
    </>
  );
}
export default IHawk;
