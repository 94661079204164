import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import { FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

export default function CloseBtn() {
  const navigate = useNavigate();
  const [showButton, setShowButton] = useState(true);
  const close = () => {
    setTimeout(() => {
      navigate(-1);
    }, 300);
  };

  useEffect(() => {
    const handleScrollButtonVisibility = () => {
      window.pageYOffset < 1000 ? setShowButton(true) : setShowButton(false);
    };
    window.addEventListener('scroll', handleScrollButtonVisibility);
    return () => {
      window.removeEventListener('scroll', handleScrollButtonVisibility);
    };
  }, []);
  return (
    <>
      {showButton && (
        <Box
          className="close_btn"
          sx={{ '& > :not(style)': { m: 1 } }}
          style={{
            position: 'fixed',
            top: '2.5rem',
            right: '2rem',
            zIndex: 1,
          }}
        >
          <Fab
            style={{
              background: '#21262b',
            }}
            onClick={close}
          >
            <FaTimes
              style={{
                fill: '#ffffff',
                fontSize: '1.5rem',
                transition: 'all 0.5s ease',
                transform: `rotate : 0.5turn})`,
              }}
            ></FaTimes>
          </Fab>
        </Box>
      )}
    </>
  );
}
