import Home from "./components/Home/Home";
import Sidebar from "./components/Sidebar/Sidebar";
import Contact from "./components/Contact/Contact";
import StickyBox from "react-sticky-box";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import "./App.scss";
import Project from "./components/Project/Project";
import Notfound from "./components/Not Found/NotFound";
import Navbar from "./components/Navbar/Navbar";
import ScrollToTop from "./utils/scrollToTop";
import Blog from "./components/Blog/Blog";

function App() {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  return (
    <Router>
      <ScrollToTop />
      {isTabletOrMobile && (
        <StickyBox style={{ zIndex: 2 }}>
          <Navbar />
        </StickyBox>
      )}
      <div
        className={`App ${isTabletOrMobile ? "mobile-view" : "desktop-view"}`}
      >
        {!isTabletOrMobile && (
          <StickyBox bottom>
            <Sidebar />
          </StickyBox>
        )}
        <div className="main">
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/blog" element={<Blog />}></Route>
            <Route path="/contact" element={<Contact />}></Route>
            <Route path="/project/:name" element={<Project />}></Route>
            <Route path="error" element={<Notfound />} />
            <Route path="*" element={<Navigate to="/error" replace />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
