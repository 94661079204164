import './Sidebar.scss';
import logo from './../../assets/images/logo.svg';
import { NavLink } from 'react-router-dom';

function Sidebar(props: any) {
  return (
    <div className="sidebar">
      <div className="cover">
        <div className="logo">
          <a href="/">
            <img src={logo} alt="My logo" />
          </a>
        </div>
        <div className="content">
          <div className="menu">
            <NavLink
              to="/"
              className={({ isActive }) => (isActive ? 'active' : 'inactive')}
            >
              Projects
            </NavLink>
            <NavLink
              to="/blog"
              className={({ isActive }) => (isActive ? 'active' : 'inactive')}
            >
              Blog
            </NavLink>
            <NavLink
              to="/contact"
              className={({ isActive }) => (isActive ? 'active' : 'inactive')}
            >
              Say Hi!
            </NavLink>
          </div>
          <div className="footer">
            <div className="social">
              <a
                href="https://www.linkedin.com/in/samuxdesigner/"
                target="_blank"
                rel="noopener noreferrer"
              >
                LinkedIn
              </a>
              <a
                href="https://medium.com/@imsam"
                target="_blank"
                rel="noopener noreferrer"
              >
                Medium
              </a>
              <a
                href="https://dribbble.com/imsam"
                target="_blank"
                rel="noopener noreferrer"
              >
                Dribble
              </a>
              <a
                href="https://www.xing.com/profile/Soumya_Ranjan2/cv"
                target="_blank"
                rel="noopener noreferrer"
              >
                Xing
              </a>
            </div>
            <div className="copyright">
              Copyright © {new Date().getFullYear()}. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
