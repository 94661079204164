import "./Project.scss";
import "./Sonar-Watch.scss";

import banner1 from "./../../assets/images/Sonar Watch/Sonar Card Cover.png";
import userPersona1 from "../../assets/images/Sonar Watch/2.png";
import userPersona2 from "../../assets/images/Sonar Watch/3.png";
import platformPage1 from "../../assets/images/Sonar Watch/4.png";
import platformPage2 from "../../assets/images/Sonar Watch/5.png";
import comparision1 from "../../assets/images/Sonar Watch/6.png";
import comparision2 from "../../assets/images/Sonar Watch/7.png";

function SonarWatch() {
  return (
    <>
      <div className="sonar_watch">
        <div className="heading">
          SonarWatch Ecosystem/Platforms Page Re-Design
        </div>
        <div className="sub_heading">B2C Web App</div>
        <div className="description">
          In the fast-evolving world of decentralised finance (DeFi), the
          ability to seamlessly navigate and discover new platforms is crucial
          for both seasoned investors and newcomers alike. SonarWatch, a leading
          DeFi dashboard covering multiple chains including Solana, SUI, and
          Aptos, recognised the potential to enhance its ecosystem page, aiming
          to transform it into a more intuitive and user-friendly hub for
          exploration. Acknowledging that their current page fell short in
          offering a comprehensive discovery experience, they set out to
          redesign it. This case study embarks on the journey of reimagining the
          SonarWatch Ecosystem/Platforms Page, with the goal of making DeFi
          exploration not just more accessible, but a delight for its diverse
          user base. Through a thoughtful design process, the project sought to
          balance simplicity with depth, ensuring that users could effortlessly
          find and engage with new platforms while being supported by a design
          that speaks the language of the Web3 world.
        </div>
        <div className="details_grid">
          <div className="grid_item">
            <div className="label">Role</div>
            <li>UX Strategy</li>
            <li>Product Design</li>
          </div>
          <div className="grid_item">
            <div className="label">Deliverables</div>
            <li>Web App</li>
          </div>
        </div>
        <div className="preview_image banner_bkg">
          <img loading="lazy" src={banner1} alt="Sonar Watch Banner" />
        </div>

        <div className="description_block">
          <div className="heading">My Web3 design philosophy</div>
          <div className="sub_heading">
            In approaching the redesign of the SonarWatch Ecosystem/Platforms
            Page, I was deeply influenced by the core principles of Web3 design
            philosophy. This philosophy priorities decentralisation,
            transparency, user empowerment, and privacy, reflecting the spirit
            of the decentralised finance (DeFi) community I aimed to serve.
            Here's how these principles shaped my design approach:
          </div>
          <div className="desc_items">
            <ul>
              <li>
                <span className="bold">Decentralisation</span>: At the heart of
                Web3 is the idea of removing central authorities and
                intermediaries. In practice, this meant creating a design that
                gives users more control over their viewing and interaction
                experiences. For instance, I integrated filters and preferences,
                allowing users to tailor the information displayed according to
                their interests and needs, much like they would control their
                assets in a decentralised ecosystem.
              </li>
              <li>
                <span className="bold">Transparency</span>: Transparency is
                crucial in building trust within the DeFi community. My design
                ensures that users have clear visibility into how platform data
                is sourced, displayed, and used. By making the mechanics of the
                dashboard transparent, users can trust the integrity of the
                information presented, fostering a stronger relationship between
                SonarWatch and its users.
              </li>
              <li>
                <span className="bold">User Empowerment</span>: Empowering users
                is a fundamental aspect of Web3, and I aimed to embed this into
                every layer of the design. This meant not only providing users
                with tools to customise their experience but also ensuring that
                the interface is intuitive, enabling them to discover and
                evaluate DeFi platforms with confidence and ease. The design
                empowers users by providing them with the information and tools
                they need to make informed decisions without feeling overwhelmed
                or controlled by the platform.
              </li>
              <li>
                <span className="bold">Privacy</span>: Respecting user privacy
                is paramount in Web3. Throughout the design process, I was
                mindful of incorporating features that respect user anonymity
                and data. The redesign doesn't force users to share more
                information than they are comfortable with, and it educates
                users on how their data might be used, adhering to the principle
                of privacy by design.
              </li>
            </ul>
          </div>
          <div className="sub_heading">
            Incorporating these Web3 design principles was not just about
            adhering to best practices; it was about aligning the redesign with
            the values of the SonarWatch community and the broader DeFi
            ecosystem.
          </div>
        </div>

        <div className="description_block">
          <div className="heading">The Problem Space</div>
          <div className="sub_heading">
            Addressing the challenges of redesigning the SonarWatch
            Ecosystem/Platforms Page required a deep dive into specific user
            experience issues. Here's an updated look at the main problems
            identified:
          </div>
          <div className="desc_items">
            <ul>
              <li>
                <span className="bold">Complex Navigation</span>: Users faced
                difficulties in navigating through the extensive list of
                platforms on SonarWatch due to the lack of effective filtering
                options. This complexity made it challenging to locate platforms
                that aligned with their investment goals or interests.
              </li>
              <li>
                <span className="bold">
                  Less Information for the Usage of the DeFi Dashboard
                </span>
                : There was a notable gap in providing users with clear, concise
                information on how to effectively use the DeFi dashboard. Users
                needed more guidance on leveraging SonarWatch to its full
                potential, which would enhance their ability to discover and
                evaluate new platforms efficiently.
              </li>
              <li>
                <span className="bold">Inadequate Web3 Integration</span>:
                Despite its foundation in the DeFi sector, the existing page
                design did not fully reflect Web3 design principles. Users were
                looking for a design that not only provided vital DeFi
                information but also resonated with the decentralized ethos of
                the community it served.
              </li>
              <li>
                <span className="bold">Accessibility Concerns</span>: The page
                did not adequately cater to users with diverse abilities, making
                navigation and comprehension more difficult for some. Improving
                accessibility was essential to make SonarWatch welcoming and
                usable for anyone interested in exploring DeFi.
              </li>
            </ul>
          </div>
        </div>

        <div className="img_reel">
          <div className="reel_header">User Persona</div>
          <div className="img_container">
            <div className="banner_bkg">
              <img loading="lazy" src={userPersona1} alt="User Persona 1" />
              <img
                loading="lazy"
                className="mt-32"
                src={userPersona2}
                alt="User Persona 2"
              />
            </div>
          </div>
        </div>

        <div className="description_block">
          <div className="heading">The Vision & Solution</div>
          <div className="sub_heading">
            As I embarked on the journey of redesigning the SonarWatch
            Ecosystem/Platforms Page, my vision was crystal clear: to create a
            user-centric platform where simplicity meets functionality. This
            vision was guided by the need to make DeFi exploration intuitive,
            informative, and accessible to everyone, from seasoned investors
            like Syed to curious newcomers like Emma. Here's how I envisioned
            and executed the solution to meet our diverse user needs:
          </div>
          <div className="bold">Asset Information:</div>
          <div className="desc_items">
            <ul>
              <li>
                <span className="bold">Enhanced Visibility</span>: I ensured
                that every asset displayed on the redesigned page featured the
                asset's name, logo, and a concise description right at the
                forefront. This approach was aimed at providing users a clear,
                immediate understanding of each asset without the need to dig
                deeper unless they wanted to. This clarity is crucial for users
                making quick, informed decisions.
              </li>
              <li>
                <span className="bold"> Network and Chains Information</span>:
                Recognising the importance of blockchain diversity in DeFi
                investments, I included detailed information about the asset's
                network and the number of chains it operates on. This feature is
                particularly useful for users looking to diversify their
                portfolio across different blockchains.
              </li>
              <li>
                <span className="bold">Category Classification</span>: Each
                asset is clearly categorised, making it easy for users to filter
                and explore platforms based on their specific interests, such as
                lending, trading, or yield farming. This categorisation
                simplifies navigation and enhances the discoverability of new
                platforms within the ecosystem.
              </li>
              <li>
                <span className="bold">Market Data</span>: Clear and up-to-date
                market prices and Total Value Locked (TVL) figures are
                prominently displayed for each asset. This financial information
                is critical for users like Syed, who rely on these metrics to
                gauge an asset's performance and potential for investment.
              </li>
              <li>
                <span className="bold">Reference Links</span>: For users who
                wish to dive deeper, each asset listing includes direct links to
                the platform's official website, Twitter page, and a shareable
                public link. This ensures that users have easy access to further
                information and can connect with the platform's community
                effortlessly.
              </li>
            </ul>
          </div>
          <div className="bold">Search & Filters for the Asset List:</div>
          <div className="sub_heading">
            Understanding the importance of an efficient discovery process, I
            designed a sophisticated search and filter system. This system
            allows users to easily navigate the vast DeFi landscape by filtering
            assets based on network, category, market price, or TVL. The aim was
            to streamline the search process, making it as effortless as
            possible for users to find platforms that match their criteria.
          </div>

          <div className="bold">
            User-Friendly Interface with Light & Dark Mode Support:
          </div>
          <div className="sub_heading">
            Acknowledging the diverse preferences of our users, the redesigned
            interface offers support for both light and dark modes. This feature
            not only caters to individual visual preferences but also enhances
            accessibility, making the platform comfortable to use at any time of
            the day. The interface is designed to be intuitive, ensuring that
            both tech-savvy users and those less familiar with DeFi can navigate
            it with ease.
          </div>
        </div>

        <div className="description_block">
          <div className="heading">Competitive Analysis</div>
          <div className="sub_heading">
            As I delved into the redesign of the SonarWatch Ecosystem/Platforms
            Page, understanding the landscape meant scrutinizing our
            competitors: DeFiLlama, CoinGecko, and De.Fi. These platforms have
            set benchmarks in the DeFi space, offering insights and
            functionalities that cater to a wide array of users. Here's my take
            on what they offer and how SonarWatch aims to carve out its unique
            space among these giants.
          </div>
          <div className="desc_items">
            <ul>
              <li>
                <span className="bold">DeFiLlama</span>- DeFiLlama is a
                powerhouse when it comes to aggregating DeFi data across
                multiple chains. Its strength lies in its comprehensive coverage
                and deep dive into DeFi metrics, such as TVL (Total Value
                Locked), which appeals to users looking for detailed financial
                analysis. However, I noticed that while the data is rich, the
                user experience can be overwhelming for newcomers. The abundance
                of information and the technical nature of its presentation can
                deter those who are new to DeFi or prefer a more streamlined
                experience.
              </li>
              <li>
                <span className="bold">CoinGecko</span>- CoinGecko is widely
                recognized for its extensive database of cryptocurrencies and
                DeFi platforms, providing valuable insights into market trends,
                prices, and volume. Its user interface is relatively friendly,
                and it offers a variety of tools for market analysis. However,
                CoinGecko primarily serves as a crypto market tracker, and while
                it does offer information on DeFi platforms, the depth of
                DeFi-specific functionalities and data presentation doesn't
                fully cater to users deeply entrenched in the DeFi ecosystem.
              </li>
              <li>
                <span className="bold">De.Fi</span>- De.Fi presents itself as a
                more focused tool for DeFi enthusiasts, offering insights and
                analytics tailored to the DeFi market. Its interface and data
                presentation are designed with the DeFi community in mind,
                making it a go-to for users seeking specialized information.
                However, its niche focus can sometimes limit its appeal to a
                broader audience that might benefit from a more holistic view of
                the crypto and DeFi world.
              </li>
            </ul>
          </div>
        </div>

        <div className="description_block">
          <div className="heading">SonarWatch's Unique Position</div>
          <div className="sub_heading">
            Drawing insights from these competitors, my vision for SonarWatch
            was to blend the best of both worlds: the depth of DeFiLlama's data,
            the user-friendly interface of CoinGecko, and the DeFi-focused
            insights of De.Fi. SonarWatch aims to stand out by offering:
          </div>
          <div className="desc_items">
            <ul>
              <li>
                <span className="bold">Streamlined User Experience</span>-
                Tailoring the design to accommodate both seasoned DeFi users and
                newcomers, ensuring that the platform is intuitive, informative,
                and easy to navigate.
              </li>
              <li>
                <span className="bold">Data Presentation</span>- Unlike the
                one-size-fits-all approach, SonarWatch allows users to customise
                how data is presented to them, catering to their specific
                interests and level of expertise.
              </li>
            </ul>
          </div>
        </div>

        <div className="heading padding-top">Final Design</div>

        <div className="img_reel">
          <div className="reel_header">Platform Page Dark</div>
          <div className="img_container">
            <div className="banner_bkg">
              <img
                loading="lazy"
                src={platformPage1}
                alt="Platform Page Dark"
              />
            </div>
          </div>

          <div className="reel_header">Platform Page Light</div>
          <div className="img_container">
            <div className="banner_bkg">
              <img
                loading="lazy"
                src={platformPage2}
                alt="Platform Page Light"
              />
            </div>
          </div>

          <div className="reel_header">Comparison (Before & After)</div>
          <div className="img_container">
            <div className="banner_bkg">
              <img
                loading="lazy"
                src={comparision1}
                alt="Comparison (Before)"
              />
            </div>
            <div className="img_caption">Before</div>
          </div>

          <div className="img_container">
            <div className="banner_bkg">
              <img loading="lazy" src={comparision2} alt="Comparison (After)" />
            </div>
            <div className="img_caption">After</div>
          </div>
        </div>

        <div className="description_block">
          <div className="heading">Result & Takeaways</div>
          <div className="sub_heading">
            The redesign of the SonarWatch Ecosystem/Platforms Page was an
            adventure akin to steering through the vibrant yet unpredictable
            seas of the DeFi world. This endeavor wasn't merely about enhancing
            a webpage; it became a deep dive into understanding and innovating
            within the DeFi community. Through this process, we've gained
            invaluable insights and achieved outcomes that have not only
            reshaped our platform but also our approach to product development
            in the DeFi ecosystem.
          </div>
          <div className="bold">Result:</div>
          <div className="sub_heading">
            My journey culminated in a platform that seamlessly blends the
            complex world of DeFi with the clarity and ease of use desired by
            both novices and experts. The introduction of user-centric features,
            such as filters and clear, concise asset information, significantly
            improved the platform's usability and engagement. By prioritising
            user experience over elaborate design elements, I have made DeFi
            exploration accessible and intuitive, as evidenced by positive
            feedback and increased user engagement metrics.
          </div>
          <div className="bold">Key Takeaways:</div>
          <div className="desc_items">
            <ul>
              <li>
                <span className="bold">Prioritisation of UX Over UI:</span> A
                major shift in my design philosophy was prioritising user
                experience flows over intricate UI details. This approach led to
                significant improvements in the platform's usability, making the
                user journey smoother and more intuitive.
              </li>
              <li>
                <span className="bold">Staying Problem-Oriented:</span>
                Throughout the development process, i remained fiercely
                dedicated to solving the core problem: navigating the vast and
                complex DeFi landscape. This unwavering focus ensured that every
                feature i introduced was purposeful and aligned with the users'
                needs.
              </li>
            </ul>
          </div>
          <div className="sub_heading">
            In conclusion, the redesign of the SonarWatch Ecosystem/Platforms
            Page transformed from a mere project into a profound journey of
            learning, adaptation, and personal growth. This experience honed my
            skills in strategic prioritisation and lean operations, and
            underscored the paramount importance of staying deeply connected
            with the user community. Armed with these insights, I am now more
            prepared than ever to continue evolving and broadening my knowledge
            and expertise in the dynamic field of UI/UX design.
          </div>
        </div>
      </div>
    </>
  );
}
export default SonarWatch;
