import './NotFound.scss';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function Notfound() {
  let navigate = useNavigate();
  const homePage = () => {
    setTimeout(() => {
      navigate('/');
    }, 300);
  };
  return (
    <div className="error_page">
      <img src={require('./../../assets/images/not_found.png')} alt="404" />
      <p className="info">Page not found</p>
      <Button onClick={homePage}>Back to home</Button>
    </div>
  );
}

export default Notfound;
