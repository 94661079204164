import "./Project.scss";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import HawkTrade from "./Hawk-Trade";
import Contact from "../Contact/Contact";
import Button from "@mui/material/Button";
import "react-photo-view/dist/react-photo-view.css";
import IHawk from "./iHawk";
import ScrollToTopBtn from "../../utils/scrollToTopBtn";
import CloseBtn from "../../utils/CloseBtn";
import StarEdutainment from "./Star-Edutainment";
import ContainerYardManagementSystem from "./Container-Yard-Management-System";
import SonarWatch from "./Sonar-Watch";

// import { PhotoProvider, PhotoView } from 'react-photo-view';

function Project() {
  let navigate = useNavigate();
  const { name } = useParams();
  const projectsPage = () => {
    setTimeout(() => {
      navigate("/");
    }, 300);
  };
  const projects = [
    "hawk-trade",
    "ihawk",
    "star-edutainment",
    "container-yard-management-system",
    "sonar-watch",
  ];
  // const projects = ['hawk-trade', 'smart-transport', 'smart-yard', 'ihawk'];
  const projectIndex = projects.findIndex((x) => name === x);

  useEffect(() => {
    if (projectIndex === -1) {
      navigate("/error");
    }
  });

  return (
    <div className="project_container">
      <CloseBtn></CloseBtn>
      <ScrollToTopBtn></ScrollToTopBtn>
      {name === "hawk-trade" && <HawkTrade></HawkTrade>}
      {name === "ihawk" && <IHawk></IHawk>}
      {name === "star-edutainment" && <StarEdutainment></StarEdutainment>}
      {name === "container-yard-management-system" && (
        <ContainerYardManagementSystem></ContainerYardManagementSystem>
      )}
      {name === "sonar-watch" && <SonarWatch></SonarWatch>}
      <div className="allWorksBtnContainer">
        <Button onClick={projectsPage}>See All My Work</Button>
      </div>
      <Contact fromProjects="true"></Contact>
    </div>
  );
}
export default Project;
