import "./Project.scss";
import "./Container-Yard-Management-System.scss";

import cymsBanner from "./../../assets/images/CMS/CMS Card Cover.png";
import dashboardPage from "./../../assets/images/CMS/Dashboard/Dashboard.png";
import planningEngine from "./../../assets/images/CMS/Rules/Rules dashboard.png";
import rulesYardArea from "./../../assets/images/CMS/Rules/Yard Area Mapping.png";
import rulesYardAreaDetails from "./../../assets/images/CMS/Rules/Yard Area Mapping – details.png";
import newRules from "./../../assets/images/CMS/Rules/Rule Configuration – choose color.png";
import bookingsDashboard from "./../../assets/images/CMS/Bookings/bookings dashboard – 1.png";
import editBooking from "./../../assets/images/CMS/Bookings/Preplanning slots – landing – slot view – edit.png";
import newBooking from "./../../assets/images/CMS/Bookings/Preplanning slots.png";
import inventory from "./../../assets/images/CMS/Inventory/Inventory.png";
import users from "./../../assets/images/CMS/Users/Users.png";
import addUser from "./../../assets/images/CMS/Users/Users – add.png";
import userRoles from "./../../assets/images/CMS/Users/Users – roles.png";
import gateInUserRoles from "./../../assets/images/CMS/Users/Users – roles2.png";

function ContainerYardManagementSystem() {
  return (
    <>
      <div className="cyms">
        <div className="heading">Container Yard Management System</div>
        <div className="sub_heading">B2B Web App</div>
        <div className="description">
          The Container Management System offers a revolutionary approach to
          container yard management by leveraging full automation and AI
          technology. This comprehensive solution digitises all aspects of yard
          management, from gate operations to container tracking, eliminating
          the need for manual intervention. Its key components include a fully
          automated yard management system, unmanned gates, and a single mobile
          app for all stakeholders, facilitating automated mobile and gate
          surveys. Additionally, it ensures 100% visual records of every
          container's movement, integrates seamlessly with Terminal Operating
          Systems (TOS) for a smooth experience, and boasts a zero-error rate
          due to its fully automated processes.
        </div>
        <div className="details_grid">
          <div className="grid_item">
            <div className="label">Organization</div>
            <li>CNB Logitech</li>
          </div>
          <div className="grid_item">
            <div className="label">Role</div>
            <li>Product Owner</li>
            <li>UX Strategy</li>
            <li>Product Design</li>
          </div>
          <div className="grid_item">
            <div className="label">Deliverables</div>
            <li>Web App</li>
          </div>
        </div>
        <div className="preview_image banner_bkg">
          <img loading="lazy" src={cymsBanner} alt="CYMS Banner" />
        </div>
        <div className="description_block">
          <div className="heading">The Pain Points</div>
          <div className="desc_items">
            <ul>
              <li>
                <span className="bold">Manual Errors</span>: By automating all
                operations, the Container Management System eliminates the
                possibility of human errors in data entry and container
                tracking.
              </li>
              <li>
                <span className="bold">Inefficiency and Wastage</span>: The
                system reduces unnecessary movements of containers and Container
                Handling Equipment (CHEs), thereby minimising wastage and
                improving yard efficiency.
              </li>
              <li>
                <span className="bold">Time-Consuming Operations</span>: The
                solution significantly reduces the Turnaround Time (TAT) of
                activities within the yard, streamlining operations.
              </li>
              <li>
                <span className="bold">Lack of Visibility</span>: With AI-driven
                visual capture of every container movement, stakeholders gain
                unprecedented visibility into yard operations, enhancing
                decision-making and operational transparency.
              </li>
              <li>
                <span className="bold">Safety Concerns</span>: The automation of
                yard operations creates a pedestrian-free environment,
                substantially improving safety standards.
              </li>
            </ul>
          </div>
        </div>
        <div className="description_block">
          <div className="heading">The Challenge</div>
          <div className="sub_heading">
            Integrating the Container Management System with existing Terminal
            Operating Systems (TOS) presents a notable challenge, as achieving
            seamless compatibility may require tailored solutions for each
            client's unique setup. Convincing stakeholders to transition to this
            new, fully automated technology can also be difficult, especially in
            regions where traditional operational methods are deeply entrenched.
          </div>
          <div className="sub_heading">
            The initial investment required to implement the Container
            Management System could deter some businesses, despite the potential
            for significant long-term savings and efficiency improvements.
            Furthermore, ensuring that users receive adequate training and
            ongoing support to effectively navigate the new system demands
            substantial resources, highlighting another challenge in the
            widespread adoption and effective use of the Container Management
            System.
          </div>
        </div>

        <div className="heading padding-top">Final Impact</div>

        <div className="img_reel">
          <div className="reel_header">Landing</div>
          <div className="img_container">
            <div className="banner_bkg">
              <img loading="lazy" src={dashboardPage} alt="Dashboard Page" />
            </div>
            <div className="img_caption">Dashboard</div>
          </div>
        </div>

        <div className="img_reel">
          <div className="reel_header">Planning Engine (Rules)</div>
          <div className="img_container">
            <div className="banner_bkg">
              <img loading="lazy" src={planningEngine} alt="Planning Engine" />
            </div>
            <div className="img_caption">Rules Dashboard</div>
          </div>
        </div>

        <div className="img_reel">
          <div className="img_container">
            <div className="banner_bkg">
              <img loading="lazy" src={rulesYardArea} alt="Rules Yard Area" />
            </div>
            <div className="img_caption">Rules Yard Area</div>
          </div>
        </div>

        <div className="img_reel">
          <div className="img_container">
            <div className="banner_bkg">
              <img
                loading="lazy"
                src={rulesYardAreaDetails}
                alt="Rules Yard Area Details"
              />
            </div>
            <div className="img_caption">Rules Yard Area Details</div>
          </div>
        </div>

        <div className="img_reel">
          <div className="img_container">
            <div className="banner_bkg">
              <img loading="lazy" src={newRules} alt="New Rule" />
            </div>
            <div className="img_caption">New Rule</div>
          </div>
        </div>

        <div className="img_reel">
          <div className="reel_header">Planning Engine (Bookings)</div>
          <div className="img_container">
            <div className="banner_bkg">
              <img
                loading="lazy"
                src={bookingsDashboard}
                alt="Bookings Dashboard"
              />
            </div>
            <div className="img_caption">Bookings Dashboard</div>
          </div>
        </div>

        <div className="img_reel">
          <div className="img_container">
            <div className="banner_bkg">
              <img loading="lazy" src={editBooking} alt="Edit Booking" />
            </div>
            <div className="img_caption">Edit Booking</div>
          </div>
        </div>

        <div className="img_reel">
          <div className="img_container">
            <div className="banner_bkg">
              <img loading="lazy" src={newBooking} alt="New Booking" />
            </div>
            <div className="img_caption">New Booking</div>
          </div>
        </div>

        <div className="img_reel">
          <div className="reel_header">Inventory</div>
          <div className="img_container">
            <div className="banner_bkg">
              <img loading="lazy" src={inventory} alt="Bookings Dashboard" />
            </div>
            <div className="img_caption">Bookings Dashboard</div>
          </div>
        </div>

        <div className="img_reel">
          <div className="reel_header">Users</div>
          <div className="img_container">
            <div className="banner_bkg">
              <img loading="lazy" src={users} alt="Users Dashboard" />
            </div>
            <div className="img_caption">Users Dashboard</div>
          </div>
        </div>

        <div className="img_reel">
          <div className="img_container">
            <div className="banner_bkg">
              <img loading="lazy" src={addUser} alt="Add New User" />
            </div>
            <div className="img_caption">Add New User</div>
          </div>
        </div>

        <div className="img_reel">
          <div className="img_container">
            <div className="banner_bkg">
              <img
                loading="lazy"
                src={userRoles}
                alt="User Role - Super Admin"
              />
            </div>
            <div className="img_caption">User Role - Super Admin</div>
          </div>
        </div>

        <div className="img_reel">
          <div className="img_container">
            <div className="banner_bkg">
              <img
                loading="lazy"
                src={gateInUserRoles}
                alt="User Role - Gate-In User"
              />
            </div>
            <div className="img_caption">User Role - Gate-In User</div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ContainerYardManagementSystem;
