import './About.scss';
import avatar from './../../assets/images/avatar.png';

function About() {
  return (
    <section className="about_me">
      <div className="avatar">
        <img src={avatar} alt="avatar" />
      </div>
      <div className="intro">
        <article className="details">
          <div className="name">Hi, I’m Soumya Ranjan!</div>
          <div className="title">Enterprise UX & Product Design Specialist</div>
          <div className="description">
            Currently UX Head at{' '}
            <span className="company_link">CNB Logitech</span>, creating the
            next generation of container booking experience. Previously at
            Capiot Software.
          </div>
        </article>
      </div>
    </section>
  );
}

export default About;
