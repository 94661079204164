import './Navbar.scss';
import logo from './../../assets/images/logo.svg';
import { NavLink } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import { useState } from 'react';

function Navbar() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };
  return (
    <>
      <header>
        <div className="logo">
          <a href="/">
            <img src={logo} alt="My logo" />
          </a>
        </div>
        {toggleMenu && (
          <button className="nav-btn nav-close-btn" onClick={toggleNav}>
            <FaTimes />
          </button>
        )}
        {!toggleMenu && (
          <button className="nav-btn" onClick={toggleNav}>
            <FaBars />
          </button>
        )}
      </header>
      {toggleMenu && (
        <div className="nav_menu">
          <NavLink
            onClick={toggleNav}
            to="/"
            className={({ isActive }) => (isActive ? 'active' : 'inactive')}
          >
            Projects
          </NavLink>
          <NavLink
            onClick={toggleNav}
            to="/blog"
            className={({ isActive }) => (isActive ? 'active' : 'inactive')}
          >
            Blog
          </NavLink>
          <NavLink
            onClick={toggleNav}
            to="/contact"
            className={({ isActive }) => (isActive ? 'active' : 'inactive')}
          >
            Say Hi!
          </NavLink>
        </div>
      )}
    </>
  );
}

export default Navbar;
