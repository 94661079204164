import './Home.scss';
import About from './../About/About';
import Projects from '../Projects/Projects';

function Home() {
  return (
    <div className="home">
      <About />
      <p className="recent_projects">Recent Projects</p>
      <Projects />
    </div>
  );
}

export default Home;
