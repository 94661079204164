import "./Contact.scss";
import { FaLinkedinIn, FaWhatsapp, FaCloudDownloadAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import SayHi from "./../../assets/images/hi.svg";

function Contact(props: any) {
  return (
    <div className="contact_me">
      <div className="msg">
        {props.fromProjects ? (
          <>
            Let’s Connect! <img className="wave" src={SayHi} alt="Hiii" />
          </>
        ) : (
          <>Get in touch for opportunities or just to say hi!</>
        )}
      </div>
      <div className="info">
        {props.fromProjects ? (
          <div className="say_hi">
            Get in touch for opportunities or just to say hi.
          </div>
        ) : (
          <>
            If you have a project in mind or you just want to chat, feel free to
            get in touch.
          </>
        )}
      </div>

      {!props.fromProjects && (
        <>
          <div className="email">
            <a href="mailto:reachme@hellosam.in">reachme@hellosam.in</a>
          </div>
          <div className="spacer">Or</div>
        </>
      )}

      <div className="social">
        <a
          href="https://www.linkedin.com/in/samuxdesigner/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button>
            <div>
              {" "}
              <FaLinkedinIn className="icon" />
              <span> Connect on LinkedIn</span>
            </div>
          </button>
        </a>
        {props.fromProjects ? (
          <a href="mailto:reachme@hellosam.in">
            <button>
              <div>
                {" "}
                <MdEmail className="icon" />
                <span>Email Me</span>
              </div>
            </button>
          </a>
        ) : (
          ""
        )}
        <a
          href="https://wa.me/+919036382511"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button>
            <div>
              {" "}
              <FaWhatsapp className="icon" />
              <span>Chat On WhatsApp</span>
            </div>
          </button>
        </a>
        <a
          href={require("./SoumyaRanjanResume.pdf")}
          download="SoumyaRanjanResume"
        >
          <button>
            <div>
              <FaCloudDownloadAlt className="icon" />
              <span>Download CV</span>
            </div>
          </button>
        </a>
      </div>
    </div>
  );
}

export default Contact;
